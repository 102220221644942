import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Home() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="bg-[#045096] pb-[94px] relative">
      <div className="absolute sm:block hidden right-0 bottom-0">
        <img src="assets/item2.png" alt="" />
      </div>
        <h1
          data-aos="fade-up"
          style={{ fontFamily: "HoneyBear" }}
          className="sm:text-[80px] text-[40px] pb-[58px] pt-[22px] text-[#FFFFFF] text-center uppercase m-0 font-[400] "
        >
          Wet kitty
        </h1>
        <div className="flex justify-center flex-col items-center">
          <img src="assets/logo.svg" data-aos="fade-right" alt="" />
          <h6
            data-aos="fade-up"
            style={{ fontFamily: "Komit" }}
            className="text-[56px] max-w-[420px] text-[#FFFFFF] pb-[48px] m-auto w-full leading-[50px] !font-[400] uppercase text-center"
          >
            $WET is here turning dry investors wet and wealthy
          </h6>
          <button
            style={{ fontFamily: "Komit" }}
            className="font-[400] text-[26px] py-[6px] px-[20px] uppercase border-[4px] border-[#000000] bg-[#FFFBB1] text-[#045096]"
          >
            BUY $Wet
          </button>
        </div>
      </div>
      <div className="line-loop-container">
        <div className="line-loop flex items-center gap-[50px]">
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
           $WET
          </h6>
        </div>
      </div>
      <div className="bg-[#FFFBB1] pt-[112px]">
        <h1
          data-aos="fade-right"
          style={{ fontFamily: "Komit" }}
          className="text-[56px] pb-[33px] text-center uppercase m-0 text-[#000000]"
        >
          HOW TO BUY
        </h1>
        <h6
          data-aos="fade-left"
          style={{ fontFamily: "Komit" }}
          className="text-[36px] text-[#000000] max-w-[538px] w-full m-auto pb-[96px] uppercase  text-center"
        >
          Follow these easy steps to become a Wet holder
        </h6>
        <div className="max-w-[1500px] m-auto px-[30px] w-full">
          <div className="grid sm:grid-cols-4 grid-cols-1 gap-[20px] pb-[100px]">
            <div className="bg-[#FFFFFF] px-[26px] py-[36px] overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[18px] uppercase m-0 font-[800] text-[#000000] pb-[16px] leading-[30px]"
              >
               Choose a Cryptocurrency Exchange:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[31px] leading-[36px] text-[#000000]"
              >
               Select a reputable exchange that supports Solana (SOL) trading
              </p>
            </div>
            <div className="bg-[#FFFFFF] px-[26px] py-[36px] overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[18px] uppercase m-0 font-[800] text-[#000000] pb-[16px] leading-[30px]"
              >
                Create an Account:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[31px] leading-[36px] text-[#000000]"
              >
               Sign up on the chosen exchange platform. Provide necessary identification and complete any verification processes required.
              </p>
            </div>
            <div className="bg-[#FFFFFF] px-[26px] py-[36px] overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[18px] uppercase m-0 font-[800] text-[#000000] pb-[16px] leading-[30px]"
              >
                Deposit Funds:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[31px] leading-[36px] text-[#000000]"
              >
               Deposit funds into your exchange account using methods like bank transfer, credit card, or cryptocurrency transfer.
              </p>
            </div>
            <div className="bg-[#FFFFFF] px-[26px] py-[36px] overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[18px] uppercase m-0 font-[800] text-[#000000] pb-[16px] leading-[30px]"
              >
              Buy Solana (SOL):
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[31px] leading-[36px] text-[#000000]"
              >
               SNavigate to the trading section of the exchange, search for Solana (SOL), and execute your purchase. You can buy SOL directly using fiat currency or exchange other cryptocurrencies for SOL.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF] overflow-hidden">
        <h1
          data-aos="fade-down"
          style={{ fontFamily: "Komit" }}
          className="text-[64px] pt-[141px] text-center uppercase m-0 text-[#045096] pb-[6px]"
        >
          SOCIALS
        </h1>
        <h6
          data-aos="fade-up"
          style={{ fontFamily: "Komit" }}
          className="text-[36px] text-[#045096] max-w-[800px] w-full m-auto pb-[80px] uppercase text-center"
        >
          Join the $WET community
        </h6>
        <ul className="flex justify-center items-center gap-[40px]">
          <Link
            className="h-[100px] rotate-[20.77deg] w-[100px]"
            target="_blank"
            to="https://x.com/wetonsol"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item1.webp"
              alt=""
              className="h-[100px] w-[100px] bg-[#000000]"
            />
          </Link>
          <Link
            className="h-[100px] rotate-[-8.3deg] w-[100px]"
            target="_blank"
            to="https://t.me/wetkittyportal"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item2.webp"
              alt=""
              className="h-[100px] w-[100px] bg-[#000000]"
            />
          </Link>
          {/* <Link
            className="h-[100px] rotate-[16.58deg] w-[100px]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item3.svg"
              alt=""
              className="h-[100px] p-[14px] w-[100px] bg-[#000000]"
            />
          </Link> */}
        </ul>
        <div data-aos="fade-up" className="flex justify-center pt-[78px]">
          <img src="assets/item1.png" alt="" />
        </div>
      </div>
    </>
  );
}

export default Home;
